import "lity";
import { fitVidsBlocks } from "./../util/fitVids";
import slick from "slick-carousel";
import { mobileMenuToggle } from "./../util/mobileMenu";
import { acfGoogleMap } from "./../util/google-maps";

export default {
	init() {
		// JavaScript to be fired on all pages
		mobileMenuToggle();
		fitVidsBlocks();
		acfGoogleMap(".acf-map");

		jQuery(".j-slick").slick();
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	}
};
