/**
 * Google Map Init
 */
export function acfGoogleMap(className) {
	jQuery(className).each(function() {
		var map = loadGoogleMap(jQuery(this));
	});
}

// Load single map
function loadGoogleMap(mapElement) {
	// Find marker elements within map.
	var $markers = mapElement.find(".marker");
	var style = mapElement.data("style");
	// Create gerenic map.
	var mapArgs = {
		zoom: mapElement.data("zoom") || 16,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles: style,
		disableDefaultUI: true,
		zoomControl: false,
		mapTypeControl: false,
		scaleControl: false,
		streetViewControl: false,
		rotateControl: false,
		fullscreenControl: false
	};
	var map = new google.maps.Map(mapElement[0], mapArgs);

	// Add markers.
	map.markers = [];
	$markers.each(function() {
		initMarker(jQuery(this), map);
	});

	// Center map based on markers.
	centerMap(map);

	// Return map instance.
	return map;
}

/**
 * initMarker
 *
 * Creates a marker for the given jQuery element and map.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @param   object The map instance.
 * @return  object The marker instance.
 */
function initMarker($marker, map) {
	// Get position from marker.
	var lat = $marker.data("lat");
	var lng = $marker.data("lng");
	var latLng = {
		lat: parseFloat(lat),
		lng: parseFloat(lng)
	};

	// Create marker instance.
	var marker = new google.maps.Marker({
		position: latLng,
		map: map
	});

	// Append to reference for later use.
	map.markers.push(marker);
	// If marker contains HTML, add it to an infoWindow.
	if ($marker.html()) {
		// Create info window.
		var infowindow = new google.maps.InfoWindow({
			content: $marker.html()
		});

		// Show info window when marker is clicked.
		google.maps.event.addListener(marker, "click", function() {
			infowindow.open(map, marker);
		});
	}
}

/**
 * centerMap
 *
 * Centers the map showing all markers in view.
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   object The map instance.
 * @return  void
 */
function centerMap(map) {
	// Create map boundaries from all map markers.
	var bounds = new google.maps.LatLngBounds();
	map.markers.forEach(function(marker) {
		bounds.extend({
			lat: marker.position.lat(),
			lng: marker.position.lng()
		});
	});

	// Case: Single marker.
	if (map.markers.length == 1) {
		map.setCenter(bounds.getCenter());

		// Case: Multiple markers.
	} else {
		map.fitBounds(bounds);
	}
}
